<template>
    <v-container fluid>
        <v-form @submit.prevent="getInvoices('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'invoice.create'}" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <TabsNav />
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-subheader class="headline">{{ $t('search_invoice') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" class="text-right">
                                <v-btn :to="{name: 'invoice.create'}" class="white--text" color="indigo">
                                    {{$t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="company" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="company" :items="companyItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="companySearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-home-city"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingCompanies" :disabled="loading"
                                                    :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                    :label="$t('company')"
                                                    @click:clear="companyItems=[]"
                                                    @click="clearCompanies"
                                                    @click:append="redirectToCompany(company)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                    <v-date-picker ref="pickerDateContract" v-model="date"
                                                   :locale="lang"
                                                   @change="dateDialog = false">
                                    </v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateContract" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="date" :error-messages="errors"
                                                  :error="!valid" :disabled="loading"
                                                  :label="$t('date_created')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="dateDialog = !dateDialog"
                                                  clearable @click:clear="date = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="invoice_status" rules=""
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="invoice_status" :disabled="loading"
                                              :items="invoice_statusItems" :error-messages="errors"
                                              prepend-icon="mdi-cash-check" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('invoice_status')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-4">
                <v-data-table :headers="filteredHeaders" :items="invoices" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalInvoices"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer
                              v-model="selected"
                              :single-select="singleSelect"
                              show-select
                              @toggle-select-all="selectAllToggle"
                >
                    <template v-slot:top>
                        <v-col cols="12">
                            <v-btn
                                class="mr-5"
                                color="primary"
                                @click="openDialogDealToInvoice" :title="$t('assign_new_client')">
                                <v-icon class="mr-2">mdi-handshake</v-icon>
                            </v-btn>


                        </v-col>
                    </template>
                    <template v-slot:footer >

                        <v-col cols="12">
                            <v-btn
                                class="mr-5"
                                color="primary"
                                @click="openDialogDealToInvoice" :title="$t('assign_new_client')">
                                <v-icon class="mr-2">mdi-handshake</v-icon>
                            </v-btn>

                        </v-col>

                    </template>
                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                        <v-simple-checkbox
                            :value="isSelected"
                            :ripple="false"
                            :readonly="item.number_deal !== ''"
                            :disabled="item.number_deal !== ''"
                            @input="select($event)"
                        ></v-simple-checkbox>
                    </template>
                    <template v-slot:item.amount="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.amount }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.date="{ item }">
                        {{ $moment(item.date).format('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="cancelDeal(item)"
                                       :readonly="item.deal_id === ''"
                                       :disabled="item.deal_id === ''"
                                       icon>
                                    <v-icon>mdi-book-cancel-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('cancel_assignment_of_transaction')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="editInvoice(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>

                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogDeal"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >{{ $t('add_deal') }}</v-toolbar>
                <v-card-text>
                    <v-col cols="12">
                        <ValidationProvider ref="company" rules="min:1"
                                            v-slot="{ errors, valid }">
                            <v-autocomplete v-model="deal" :items="dealItems"
                                            :error-messages="errors" :error="!valid"
                                            :search-input.sync="dealSearching"
                                            item-text="number" item-value="id"
                                            prepend-icon="mdi-handshake"
                                            append-icon="mdi-location-enter"
                                            :loading="loadingDeals" :disabled="loading"
                                            :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                            :label="$t('deal.self')"
                                            @click:clear="dealItems=[]"
                                            @click="clearDeals"
                                            color="primary" clearable autocomplete="off">
                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin"></span>
                                            </span>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin"></span>
                                            </span>
                                </template>
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="closeDealToInvoice()"
                    >{{ $t('close') }}</v-btn>
                    <v-btn
                        text
                        @click="addDealToInvoice()"
                    >{{ $t('add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import debounce from "lodash/debounce";
    import TabsNav from "@/components/Leentech/TabsNav.vue";

    export default {
        name: "InvoicesCorrection",
        components: {
            TabsNav,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                singleSelect: false,
                selected: [],
                disabledCount: 0,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                dialogDeal: false,

                date: null,
                dateDialog: false,
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,
                loadingClients: false,
                invoice_status: null,
                invoice_statusItems: [],
                fab: false,
                invoice: null,
                invoices: [],
                invoiceItems: [],
                sortBy: "id",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 7,
                totalInvoices: 0,
                options: {},
                loading: false,
                headers: [
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "amount"
                    },
                    {
                        text: this.$t('number_deal'),
                        align: "center",
                        sortable: true,
                        value: "number_deal"
                    },
                    {
                        text: this.$t('invoice_status'),
                        sortable: true,
                        value: "invoice_status"
                    },
                    {
                        text: this.$t('date'),
                        sortable: true,
                        value: "date"
                    },
                    {
                        text: this.$t('company'),
                        align: "left",
                        sortable: true,
                        value: "company.name"
                    },
                    {
                        text: this.$t('manager'),
                        align: "left",
                        sortable: true,
                        value: "admin.name"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 150,
                    },
                ]
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getInvoiceStatuses()
        },
        watch: {
            options: {
                handler() {
                    this.getInvoices()
                },
                deep: false
            },
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val) {
                    this.getDeals(val)
                }
            }, 500),
        },
        methods: {
            selectAllToggle(props) {
                if(this.selected.length != this.invoices.length - this.disabledCount) {
                    this.selected = [];
                    const self = this;
                    props.items.forEach(item => {
                        if(!item.number_deal) {
                            self.selected.push(item);
                        }
                    });
                } else this.selected = [];
            },

            async getDeals(str) {
                this.loadingDeal = true
                let params = {};
                params.filter = 'not_invoice'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data;
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.$toastr.error(this.$t('failed_to_get_deals'))
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            },
            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            closeDealToInvoice(){
                this.dialogDeal  = false
                this.deal = null
                this.dealItems = []
                this.dealSearching = null

            },
            async addDealToInvoice(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                this.selected.forEach(function callback(item, key) {
                    formData.append(`invoices_uuid[${key}]`, item.uuid)
                });

                if (this.deal ) {
                    formData.append('deal', this.deal )
                }
                this.selected = [];
                this.getInvoices()

                // Add
                await this.$http
                    .post('admin/invoice/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.closeDealToInvoice();
                        this.selected = [];
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async cancelDeal(item) {
                if (confirm(this.$t('are_you_sure_you_want_to_cancel_assignment_of_transaction'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/invoice/${item.id}/cancel/deal/${item.deal_id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('deal_was_canceled'))
                            this.getInvoices()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('deal_was_not_canceled'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            openDialogDealToInvoice(){
                this.deal = null
                this.dealItems =  []
                this.dealSearching = null
                this.dialogDeal   = true
            },

            editInvoice(item) {
                this.$router.push({
                    name: 'invoice.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async deleteInvoice(item) {
                if (confirm(this.$t('delete_invoice'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/invoice/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('invoice_has_been_deleted'))
                            this.getInvoices()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('invoice_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getInvoices(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.invoice) {
                    params.invoice = this.invoice;
                }

                if (this.date) {
                    params.date = this.date;
                }

                if (this.invoice_status) {
                    params.invoice_status = this.invoice_status;
                }



                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }


                await this.$http
                    .get("admin/invoice", {
                        params: params,
                    })
                    .then(res => {
                        this.invoices = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalInvoices = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                        this.invoices.map(item => {
                            if (item.number_deal !== ''){
                                this.disabledCount = this.disabledCount + 1
                            }
                        })
                    })
                    .catch(err => {
                        this.invoices = []
                        this.totalInvoices = 0
                        this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            async getInvoiceStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/invoice_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.invoice_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.invoice_statusItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                    });
            },
        }
    }
</script>
